import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import Container, { ContainerProps } from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, Typography } from '@mui/material';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { faBars, faClipboard, faClipboardListCheck, faMemoCircleInfo, faReply, faRightFromBracket } from "@fortawesome/pro-duotone-svg-icons";
import { defaultLogOut } from '@jupol/axios-config';
import { setThemeMode } from '../theme/themeSlice';
import { ThemeSwitch } from '../theme/ThemeSwitch';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { routes } from '../../routes';

interface HeaderProps {
    containerProps?: ContainerProps
}
export function Header({ containerProps }: HeaderProps) {
    const dispatch = useAppDispatch();
    const mode = useAppSelector(state => state.theme.mode);
    const navigate = useNavigate();
    const [anchorElNav, setAnchorElNav] = React.useState<HTMLElement | null>(null);

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => setAnchorElNav(event.currentTarget);
    const handleCloseNavMenu = () => setAnchorElNav(null);

    const menuItems = [
        <Box py={1} display="flex" alignItems="center" color="inherit" style={{ textDecoration: "none" }} component={RouterLink} to={routes.endorsementResumePage.path}><FontAwesomeIcon icon={faMemoCircleInfo} fontSize={24} /><Typography ml={1}>Resumen de avales</Typography></Box>,
        <ThemeSwitch mode={mode} onChange={(mode) => dispatch(setThemeMode(mode))} />,
        <Button variant="text" color="inherit" startIcon={<FontAwesomeIcon icon={faRightFromBracket} />} onClick={() => defaultLogOut()}>Salir</Button>
    ];


    const menuInXs = (
        <Box sx={{ flexGrow: 0, display: { xs: 'flex', md: 'none' } }}>
            <IconButton size="large" onClick={handleOpenNavMenu} color="inherit">
                <FontAwesomeIcon icon={faBars} />
            </IconButton>
            <Menu
                anchorEl={anchorElNav}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                keepMounted
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
            >
                {menuItems.map((item, index) => (
                    <MenuItem key={index} onClick={() => {
                        if (item.type !== ThemeSwitch) handleCloseNavMenu();
                    }}>
                        {item}
                    </MenuItem>
                ))}
            </Menu>
        </Box>
    );

    const menuInRest = (
        <Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' } }}>
            {menuItems.map((item, index) => React.cloneElement(item, { key: index, sx: { ml: 2 } }))}
        </Box>
    );

    return (
        <AppBar position="relative" color="transparent" sx={{ color: 'text.primary' }} elevation={0}>
            <Container {...containerProps}>
                <Toolbar disableGutters>
                    <Box display="flex" alignItems="center" sx={{ 'flexGrow': 1 }}>
                        <IconButton color="inherit" onClick={() => navigate(routes.electionListPage.path)} size="small" sx={{ mr: 2, alignSelf: "center" }}>
                            <FontAwesomeIcon icon={faReply} />
                        </IconButton>
                        <Link to="" component={RouterLink} color="inherit" underline="none">
                            <Typography variant="h4" component="h1" fontFamily="Ethnocentric">
                                ATENEA
                            </Typography>
                        </Link>
                    </Box>
                    {menuInXs}
                    {menuInRest}
                </Toolbar>
            </Container>
        </AppBar>
    );
}
