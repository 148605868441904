import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getCurrentUser } from "./authAPI";
import { UserResponse } from "./interfaces/UserResponse";

export type Status = "idle" | "fulfilled" | "rejected";

export interface AuthState {
    status: Status,
    user?: UserResponse
};

export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        status: "idle",
        user: undefined
    } as AuthState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchCurrentUser.rejected, (state) => { state.status = 'rejected'; });
        builder.addCase(fetchCurrentUser.fulfilled, (state, action) => {
            state.status = 'fulfilled';
            state.user = action.payload;
        });
    }
});

export default authSlice.reducer;

//Thunk functions
export const fetchCurrentUser = createAsyncThunk('auth/fetchCurrentUser', async () => {
    const response = await getCurrentUser()
    return response.data;
});