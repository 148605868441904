import axios from "axios";

export const loadCredentials = () => {
    axios.defaults.headers.common['jplawt'] = import.meta.env.VITE_API_JPLAWT || getCookie('jplawt') || "";
    axios.defaults.headers.common['jplcwt'] = import.meta.env.VITE_API_JPLCWT || getCookie('jplcwt') || "";
}

export const setJplawt = (jplawt: string) => {
    axios.defaults.headers.common['jplawt'] = jplawt;
    setCookie("jplawt", jplawt);
}

export const getMainServerUrl = () => {
    let url: string;
    if (location.host.includes("gesid101")) url = "https://gesid101.info";
    else if (location.host.includes("gesid")) url = "https://gesid.info";
    else url = "http://localhost:8080";
    return url;
}

export const redirectToLoginPage = () => {
    location.href = getMainServerUrl();
}

export const loadBaseUrl = () => {
    const isDepevopment = () => ["http://127.0.0.1", "http://localhost"].some(e => location.origin.includes(e));
    axios.defaults.baseURL = (isDepevopment() ? "http://localhost:8080" : `https://atenea.${location.host}`);
}

export const getCookie = (attr: string) => {
    const cookie = document.cookie.split(";").map(e => e.trim());
    const index = cookie.findIndex(e => e.indexOf(`${attr}=`) === 0);
    return (index !== -1 ? cookie[index].replace(`${attr}=`, "") : undefined);
}

export const setCookie = (attr: string, value: string) => {
    document.cookie = `${attr}=${value}; Path=/;`;
}

export const deleteCookie = (attr: string) => {
    document.cookie = `${attr}=; Path=/;`;
}