import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getProvinces } from "./provincesAPI";
import { ProvincesResponse } from "./interfaces/ProvincesResponse";

export type Status = "idle" | "fulfilled" | "rejected";

export interface ProvincesState {
    status: Status,
    provinces?: ProvincesResponse
};

export const provincesSlice = createSlice({
    name: 'provinces',
    initialState: {
        status: "idle",
        provinces: undefined
    } as ProvincesState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchProvinces.rejected, (state) => { state.status = 'rejected'; });
        builder.addCase(fetchProvinces.fulfilled, (state, action) => {
            state.status = 'fulfilled';
            state.provinces = action.payload;
        });
    }
});

export default provincesSlice.reducer;

//Thunk functions
export const fetchProvinces = createAsyncThunk('provinces/fetchProvinces', async () => {
    return await getProvinces();
});