import axios from "axios";
import { useEffect } from "react";
import { deleteCookie, getMainServerUrl } from "../utils";

export interface AxiosJplawtCheckedProps {
    onJplawtExpire?: () => void;
}
export default function AxiosJplawtChecked({ onJplawtExpire }: AxiosJplawtCheckedProps) {
    // Set auth token in headers
    useEffect(() => {
        const handleCheckSession = () => {
            checkSession().then(res => {
                if (!res.active) {
                    ['jplawt', 'jplcwt'].forEach(attr => {
                        axios.defaults.headers.common[attr] = undefined;
                        deleteCookie(attr);
                        onJplawtExpire?.();
                    });

                }
            });
        }

        handleCheckSession();
        const interval = window.setInterval(handleCheckSession, 603 * 1000); //each 603 seconds

        return () => clearInterval(interval);
    });

    return null;
}

export const checkSession = async () => (await axios.post<{ active: boolean }>(getMainServerUrl() + '/ajax', undefined, { params: { dataSource: "checkSession" } })).data;