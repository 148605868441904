import React from 'react';
import Loading from './features/axios/loading/Loading';

const addReactSuspense = (c: JSX.Element) => (<React.Suspense fallback={<Loading open />}>{c}</React.Suspense>);

//Lazy loading imports
const CreateElectionPage = React.lazy(() => import("./features/election/CreateElectionPage"));
const ShowElectionPage = React.lazy(() => import("./features/election/ShowElectionPage"));
const ElectionListPage = React.lazy(() => import("./features/election/ElectionListPage"));
const EndorsementResumePage = React.lazy(() => import("./features/endorsementResume/EndorsementResumePage"));

//Routes
export const routes = {
  showElectionPage: { path: "/votaciones/:id", element: addReactSuspense(<ShowElectionPage />) },
  createElectionPage: { path: "/votaciones/crear", element: addReactSuspense(<CreateElectionPage />) },
  electionListPage: { path: "/votaciones", element: addReactSuspense(<ElectionListPage />) },
  endorsementResumePage: { path: "/resumen-avales", element: addReactSuspense(<EndorsementResumePage />) },
};