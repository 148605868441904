import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit"
import authReducer from '../features/auth/authSlice';
import provincesReducer from '../features/provinces/provincesSlice';
import themeReducer from '../features/theme/themeSlice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    provinces: provincesReducer,
    theme: themeReducer,
  },
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
