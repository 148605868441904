import { ReactNode, useEffect, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "./app/hooks";
import { getCustomTheme } from "./features/theme/customMuiTheme";
import { Container, CssBaseline, Grid, ThemeProvider, alpha } from "@mui/material";
import { Header } from "./features/header/Header";
import { BrowserRouter, Navigate, Outlet, Route, Routes } from "react-router-dom";
import background from "./style/background.jpg";
import { routes } from "./routes";
import { loadDayjsConfig } from "./config/dayjsConfig";
import AxiosLoading from "./features/axios/loading/AxiosLoading";
import { loadBaseUrl, loadCredentials, redirectToLoginPage, setJplawt } from "./features/axios/utils";
import AxiosJplawtChecked from "./features/axios/checkJplawt/AxiosJplawtChecked";
import { fetchProvinces } from "./features/provinces/provincesSlice";

loadBaseUrl();
loadCredentials();
loadDayjsConfig();

export default function App() {
  const themeMode = useAppSelector(state => state.theme.mode);
  const provincesStatus = useAppSelector(state => state.provinces.status);
  const theme = useMemo(() => getCustomTheme(themeMode), [themeMode]);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (provincesStatus === "idle") dispatch(fetchProvinces());
  }, [provincesStatus, dispatch])

  let searchParams = new URLSearchParams(window.location.search);
  const jplawt = searchParams.get("jplawt");
  if (jplawt) setJplawt(jplawt);

  return (
    <BrowserRouter basename={import.meta.env.VITE_BASE_NAME || undefined}>
      <ThemeProvider theme={theme}>
        <div style={{ background: `url(${background})`, boxShadow: `inset 0 0 0 1000px ${alpha(theme.palette.background.default, 0.90)}`, minHeight: "100vh" }}>
          <CssBaseline />
          <Routes>
            <Route element={<Layout />}>
              {Object.values(routes).map((e, index) => (
                <Route key={index} path={e.path} element={e.element} />
              ))}
              <Route path="*" element={<Navigate to={routes.electionListPage.path} />} />
            </Route>
          </Routes>
          <AxiosLoading />
          <AxiosJplawtChecked onJplawtExpire={() => redirectToLoginPage()} />
        </div>
      </ThemeProvider>
    </BrowserRouter>
  )
}

const Layout = () => {
  return (
    <>
      <Header />
      <Container sx={{ pt: { xs: 2, lg: 4 }, pb: 4 }}>
        {/* <AlignCenterExceptXs> */}
        <Outlet />
        {/* </AlignCenterExceptXs> */}
      </Container>
    </>
  )
};

export interface AlignCenterExceptXsProps { children: ReactNode }
export const AlignCenterExceptXs = ({ children }: AlignCenterExceptXsProps) => {
  return (
    <Grid container sx={{ minHeight: { xs: "auto", md: "70vh" }, display: "flex", alignItems: "center" }}>
      {children}
    </Grid>
  )
}