import { createTheme, PaletteMode, responsiveFontSizes } from "@mui/material";

export const getCustomTheme = (mode: PaletteMode) => responsiveFontSizes(createTheme({
    palette: {
        mode,
        background: {
            paper: (mode === "dark" ? "#121212" : "#e9e9e9")
        },
    },
    components: {
        MuiTextField: {
            defaultProps: {
                fullWidth: true,
                inputProps: {
                    "aria-autocomplete": "none"
                }
            }
        },
        MuiAccordion: {
            styleOverrides: {
                root: {
                    border: "1px solid #878787"
                }
            }
        },
        MuiAccordionSummary: {
            styleOverrides: {
                root: {
                    background: (mode === "dark" ? "#393939" : "#fefefe"),
                }
            }
        },
        MuiAccordionDetails: {
            styleOverrides: {
                root: {
                    background: (mode === "dark" ? "#2b2b2b" : "#f5f5f5")
                }
            }
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    border: "1px solid #878787"
                }
            }
        },
        MuiCardHeader: {
            styleOverrides: {
                root: {
                    background: (mode === "dark" ? "#00000003" : "#c3c3c3"),
                }
            }
        },
        MuiCardContent: {
            styleOverrides: {
                root: {
                    background: (mode === "dark" ? "#2b2b2b" : undefined),
                    ":last-child": {
                        paddingBottom: 16
                    }
                }
            }
        }
    }
}));